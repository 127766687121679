import {Layout, Hero} from "components/layout"
import SEO from "components/seo"
import { Section, Typography, Button, Container, Row, SectionTitle, Link } from "components/ui"
import React from "react"
import rewardfulLogo from "images/rewardful-logo.svg"

const AffiliatePage = () => (
  <Layout>
    <SEO refKey="affiliate" />
    <Section color="alt">
      <Container>
        <Row>
          <div className="col-md-12 text-center">
            <SectionTitle
              tag="h1"
              align="center"
              title={(<>Share a better way of making backups. <span className="font-black">Earn 15% commission!</span></>)}
              subTitle={(<>
                Earn 15% recurring commission by partnering up with us every month.<br/> Your referred customers will also get 15% off for the first year.

                <div className="flex gap-6 items-center justify-center mt-12">
                  <Button
                    size="large"
                    href="https://simplebackups.getrewardful.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Join the program
                  </Button>
                  <Button
                    size="large"
                    href="https://simplebackups.getrewardful.com"
                    target="_blank"
                    color="primary-border"
                    rel="noopener noreferrer"
                  >
                    Access your affiliate dashboard
                  </Button>
                </div>

                <div className="flex items-center justify-center mt-12">
                  <div className="flex flex-col items-center">
                    <img src={rewardfulLogo} className="w-48"/>
                    <p className="mt-3 text-gray-500 text-base italic font-normal">Dashboard & Processing powered by</p>
                  </div>
                </div>
              </>)}
            />
          </div>
        </Row>
      </Container>
    </Section>

    <Section padding>
      <Container>
        <Row>
          <div className="col-md-12">
            <div className="">
              <div className="text-center">
              <Typography tag="h2" calssName="text-center">
                How it works
              </Typography>
              </div>
              <dl className="mt-10 space-y-10 max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">

                <div className="relative">
                  <dt>
                    <div
                      className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <i className="fa fa-computer-mouse text-white"></i>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Sign up to our affiliate program</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    Sign up to our program and get access to your referral dashboard and your unique referral link.
                  </dd>
                </div>

                <div className="relative">
                  <dt>
                    <div
                      className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <i className="fa fa-link text-white"></i>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Share your referral link</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    Share your referral link to your friends, customers or audience.<br/>
                    They'll benefit a 15% discount on their first year.<br/>
                    Every account created within 30 days after having clicked on your link will be assigned to you.
                  </dd>
                </div>

                <div className="relative">
                  <dt>
                    <div
                      className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <i className="fa fa-circle-dollar text-white"></i>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Get paid monthly</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    You'll get a commission of 15% of all the accounts you've referred to us, paid monthly.
                  </dd>
                </div>

              </dl>
            </div>
          </div>
        </Row>
        <Row>
          <div className="col-md-12">
            <div className="">
              <div className="text-center">
                <Typography tag="h2">A few reasons your network will need SimpleBackups</Typography>
              </div>
              <dl className="mt-10 space-y-10 max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">

                <div className="relative">
                  <dt>
                    <div
                      className="absolute flex items-center justify-center h-12 w-12 rounded-md text-green-400">
                      <i className="fa fa-check"></i>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Backups Are Crucial</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    Anyone needs backup. If you manage applications, databases or anything hosted you'll need a proper backup service in place.
                  </dd>
                </div>

                <div className="relative">
                  <dt>
                    <div
                      className="absolute flex items-center justify-center h-12 w-12 rounded-md text-green-400">
                      <i className="fa fa-check"></i>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Easy & Quick setup</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    No complex setup, lots of buttons or forms, just a few clicks and your backups will be working smoothly.
                  </dd>
                </div>

                <div className="relative">
                  <dt>
                    <div
                      className="absolute flex items-center justify-center h-12 w-12 rounded-md text-green-400">
                      <i className="fa fa-check"></i>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">No need for other tools</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    SimpleBackups works for all you projects! Whether your project is a Laravel app, a WordPress website, a Magento store, runs on Django or Ruby on Rails.<br/>
                    We support all major database technologies and storage.
                  </dd>
                </div>

                <div className="relative">
                  <dt>
                    <div
                      className="absolute flex items-center justify-center h-12 w-12 rounded-md text-green-400">
                      <i className="fa fa-check"></i>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Built for teams & agencies</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    Invite your team members, share access and administrate all your backups in one place.
                  </dd>
                </div>

                <div className="relative">
                  <dt>
                    <div
                      className="absolute flex items-center justify-center h-12 w-12 rounded-md text-green-400">
                      <i className="fa fa-check"></i>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Security first</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    Encrypt your backups via AES-256, store them on your server, nothing is stored outside of your infrastructure.
                  </dd>
                </div>

              </dl>

            </div>
          </div>
          <div className="text-center mt-20 ">
            <Link color="primary" href="mailto:hello@simplebackups.com" arrow="right"> Need more materials or a "convince my boss" sheet?
            </Link>
          </div>
        </Row>
      </Container>
    </Section>

    <Section color="alt">
      <Container>
        <Row>
          <div className="col-md-12">
            <div className="">
              <div className="text-center">
                <Typography tag="h2">Affiliate Terms</Typography>
                <p className="text-gray-500 text-2xl mb-12">There are a few rules about our affiliate program you should know about.<br/>No “gotchas” here, just some terms to keep everyone happy.</p>
              </div>
              <ul className="mt-10 space-y-4 max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 list-disc">

                <li>Self-referrals are not allowed (i.e. signing up for SimpleBackups through your own affiliate link)</li>
                <li>Abuse, gaming, or attempting to mislead (i.e. posting fake discounts to coupon-sharing websites) will result in your account being permanently banned.</li>
                <li>In some cases, we can give credit to an affiliate even if the customer didn't sign up through an affiliate link or coupon code. If you have a case like this, please contact us and we’ll do our best to help.</li>
                <li>No search engine ads (especially on branded terms or domain names), Facebook ads or other ads that would compete with our own marketing and cause potential confusion for customers.</li>
                <li>No Facebook ads that link to our website or anything similar that would compete with our own paid marketing and drive up our costs and potentially cause confusion.</li>
                <li>No pretending to be acting on behalf of us (ie. as an employee).</li>
                <li>We reserve the right to change the Terms of Service for our affiliate program at any time.</li>
                <li>If your affiliate creates an account right from a marketplace (like the DigitalOcean Market place) we won't be able to attribute the commission to you.</li>

              </ul>

            </div>
          </div>
          <div className="flex gap-6 items-center justify-center mt-12">
            <Button
              size="large"
              href="https://simplebackups.getrewardful.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Join the program
            </Button>
          </div>
        </Row>
      </Container>
    </Section>

  </Layout>
)
export default AffiliatePage
