import AffiliatePage from "./affiliate/_affiliatePage";

export default AffiliatePage

export const frontmatter = {
  pageMetaData: {
    refKey: "affiliate",
    title: "SimpleBackups Affiliate Program",
    description: "Share a better way of making backups to your audience. Earn 30% recurring commission by referring simplebackups.com to your network.",
    author: "simplebackups.com"
  }
}